.topnav {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    position: fixed;
    background-color: #e6effe;
    width:calc(100% );
    z-index:10;
}

.topnav__right-item button{
    background:transparent;
    border:none;
    outline:none;
}

.topnav__right-item button.active{
    font-weight: bold;
    
    color:#F37435;
    /* border-bottom: 2px solid #0072bc; */
}

.topnav__search {
    position: relative;
    height: 35px;
    width:400px;
    background-color: var(--third-bg-dark);
    display: flex;
    align-items: center;
    box-shadow: var(--box-shadow-dark);
    border-radius:var(--border-radius);
    overflow: hidden;
    border:2px solid var(--second-bg-dark);
}

.topnav__search > input {
    height: 100%;
    width: 100%;
    padding: 10px 60px 10px 50px;
    font-size: 0.7rem;
    border-radius: var(--border-radius);
    color: var(--txt-color-dark);
    background-color: var(--third-bg-dark);
}

.topnav__search > i {
    font-size: 1rem;
    position: absolute;
    left: 20px;
    color:var(--txt-color-dark);
}

.topnav__right {
    display: flex;
    align-items: center;
}

.topnav__right i{
    font-size:1rem;
}

.topnav__right-item ~ .topnav__right-item {
    margin-left: 25px;
}

.notification-item {
    display: flex;
    align-items: center;
    padding:5px 15px;
    font-size:1rem;
}

.notification-item:hover {
    background-color: var(--second-bg);
}

.notification-item > i {
    margin-right: 20px;
    font-size: 1rem;
}

.topnav__right-user {
    display: flex;
    align-items: center;
}

.topnav__right-user__image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.topnav__right-user__image > img {
    width: 100%;
}

.topnav__right-user__name {
    font-size: 1rem;
    font-weight: 600;
}
@media screen and (max-width: 900px) {

.topnav__right-user__name {
   display:none;
}
.topnav{
    width:100%;
}
.topnav__right-item ~ .topnav__right-item {
    margin-left: 10px;
}

}