.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker{
  margin: 0;
  padding: 0;
  transform: translate(-50%,-50%);
}
.crime-marker img {
  width: 35px;
  /* border:2px solid #222; */
    filter: drop-shadow(1px 1px 5px #0003) 
            drop-shadow(-1px -1px 5px #0003);
}

.title{
  font-size:2rem;
}

._key{
  font-size:1rem;
}

.deviceDetailsContainer{
  display: none; 
  width: 300px;
   height: auto;
   position: absolute;
   top: 100px;
   right: 80px;
   z-index: 10;
   background: #242424e8;
   backdrop-filter: drop-shadow(2px 2px 5px #000) blur(8px) brightness(120%) saturate(180%);
   padding: 20px;
   border-radius: 20px;
   border:4px solid #eee4;
   color:white;
}

p{
  display:inline-block;
  margin:5px;
}

._key{
  margin-bottom:2px;
}

h1,h2,h3,h4,h5{
  margin:5px;
}

.close{
  position:absolute;
  right:10px;
  top:10px;
  background-color:#fffa;
  width:30px;
  height:30px;
  color:black;
  display:flex;
  justify-content:center; 
  align-items:center;
  border-radius:50%;
  cursor:pointer;
  opacity:0.8;
}

.bottomLeft{
  position: absolute;
  bottom:5px;
  left:10px;
}

.bottomLeft img{
  width:200px;
  border-radius:10px;
}

.sidemenu{
  display:flex;
  flex-direction:column;
  transition:all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.sidemenu-item{
  background:#fffc;
  padding:5px 10px;
  border-radius:30px;
  width: auto;
  margin:5px;
  color:#F37435;
  border:2px solid #F37435;
  backdrop-filter:blur(7px);
}

.sidemenu.hide{
  transform:scale(0);
  scale:0;
  margin-bottom:-100px;
  transition:all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275); 
}